@import "@/styles/scss/_variables.scss";
































































































.jobOfferCard {
  border: 2px solid transparent;
  transition: border .2s ease 0s, box-shadow .1s ease 0s;

  &__headline {
    margin-bottom: 5px;
    display: flex;
  }
  &__logo {
    margin: 5px 15px 0 0;
  }
  &__title {
    color: $cl-mako;
  }
  &__description {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $cl-mako-lighten-16;

    strong {
      font-weight: normal;
    }
  }

  &__company {
    margin-bottom: 5px;
    color: $cl-mako;
  }

  &__location,
  &__posted {
    display: flex;
    align-items: center;
    color: $cl-mako-lighten-16;
    &Icon {
      margin-right: 5px;
      fill: $cl-mako-lighten-16;
    }
  }
  &__location {
    margin-bottom: 5px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__apply {
    margin-top: 20px;
  }

  &__loader {
    &.aj-loader {
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &.-placeholder {
    height: 300px;
    position: relative;
  }
}
.jobOffersList__card {
  &:hover {
    .jobOfferCard {
      box-shadow: $cl-black-alpha-15 0px 6px 10px;
      border: 2px solid $cl-mako-lighten-64;
    }
  }
  &.-focused,
  &:focus {
    .jobOfferCard {
      border: 2px solid $cl-primary;
    }
  }
}
